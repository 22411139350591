<template>
  <v-list-item class="apListItem" v-on="on" @click="archive">
    <v-list-item-icon>
      <v-icon size="18" class="mr-2" small style="margin-top: -6px !important"
        >$files</v-icon
      >
    </v-list-item-icon>
    <v-list-item-content>
      <span> {{ $t("archivate", { name: $tc("") }) }}</span>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ArchiveService",
  props: ["subservice"],

  methods: {
    ...mapActions("subservices", ["archiveSubService"]),
    archive() {
      this.$confirm(
        this.$t("Estas seguro de que quieres eliminar este servicio?")
      ).then(() => {
        this.archiveSubService({
          service_id: this.subservice.parent_id,
          id: this.subservice.id,
        }).then((response) => {
          if (response) {
            this.$emit("update");
            console.log("CORRECT");
            this.$alert(this.$t("subservice_archived"));
          } else {
            console.log("ERROR");
          }
        });
      });
    },
  },
};
</script>
